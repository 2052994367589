import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import { handleDataLayerClick, TagEventTracker } from 'components/TagEventTracker';

import { nl2br } from '../../utils/functions';
import { StoreListProps } from './models';

import './StoreList.scss';

const StoreList: FC<{ data: StoreListProps }> = ({
  data: {
    storeList: {
      urls,
      seo,
      title,
      description,
      logo,
      link,
      imageDesktop,
      imageMobile,
      items,
      headband,
    },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent {...{ headband }}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <TagEventTracker eventType="page_view" data={{ value: seoTitle }}>
        <div className="store">
          <div className="store__banner">
            <Image
              imageData={imageDesktop}
              alt={imageDesktop.altText}
              className="d-none d-md-block"
            />
            <Image
              imageData={imageMobile}
              alt={imageMobile.altText}
              className="d-block d-md-none"
            />
            <div className="store__banner-text">
              <div className="container-fluid">
                <div className="row">
                  <div className="col text-center">
                    <h2 className="mb-0 mb-4">{title}</h2>
                    <DangerouslySetInnerHtml
                      html={nl2br(description)}
                      element="p"
                      className="mb-0"
                    />
                    <Image
                      imageData={logo}
                      alt={logo.altText}
                      className="d-none d-md-block mx-auto"
                    />
                    <a
                      href={link.url}
                      className="d-block py-2 mt-4"
                      target="_blank"
                      rel="noreferrer"
                      aria-label={link.name}
                    >
                      {link.name}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="store__items py-4 px-3">
            <div className="container-fluid">
              <div className="row justify-content-center">
                {items.map(({ link: store, image }) => (
                  <div className="col-12 col-md-3" key={store.url}>
                    <div className=" store__items-item mb-4">
                      <div>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            if (handleDataLayerClick)
                              handleDataLayerClick({}, 'retailer_link_click', store.url);
                          }}
                          href={store.url}
                          target="_blank"
                          rel="noreferrer"
                          aria-label={store.name}
                        >
                          <Image imageData={image} alt={store.name} />
                        </a>
                      </div>
                      <div>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            if (handleDataLayerClick)
                              handleDataLayerClick({}, 'retailer_link_click', store.url);
                          }}
                          href={store.url}
                          target="_blank"
                          className="py-3 d-block"
                          rel="noreferrer"
                          aria-label={store.name}
                        >
                          {store.name}
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </TagEventTracker>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    storeList(url: { eq: $url }) {
      urls {
        lang
        href
      }
      headband {
        cta {
          url
          name
        }
        imageDesktop {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
        imageMobile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo {
        ...SEOStructureFragment
      }
      title
      description
      link {
        url
        name
      }
      logo {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 410)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 410)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 410)
          }
        }
      }
      imageDesktop {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
      }
      imageMobile {
        altText
        fallbackUrl
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
      }
      items {
        image {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
        }
        link {
          url
          name
        }
      }
    }
  }
`;

export default StoreList;
